/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Preloader from "../UI/Preloader/Preloader";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import MyToggleAsset from "../UI/MyDropdown/MyToggleAsset";
import MyMenu from "../UI/MyDropdown/MyMenu";
import PlatformService from "../../API/PlatformService";
import { AuthContext } from "../../context";
import Alert from 'react-bootstrap/Alert';
import MyModal from "../UI/MyModal/MyModal";
import AddWarehouseForm from "./AddWarehouseForm";
import WarehouseService from "../../API/WarehouseService";
import WarehouseList from "./WarehouseList";
import EditWarehouseForm from "./EditWarehouseForm";
import WarehousePhoto from "./WarehousePhoto";
import Auth from "../../API/Auth";
import WarehouseCopyForm from "./WarehouseCopyForm";
import MyConfirm from "../UI/MyConfirm/MyConfirm";

function WarehouseArea(props) {

    const [isPreloader, setIsPreloader] = useState(false);
    const [errors, setErrors] = useState([]);
    const [successDelete, setSuccessDelete] = useState(false);
    const [deletingAsset, setDeletingAsset] = useState(false);
    const [currentAsset, setCurrentAsset] = useState({});

    //пагинация
    const [isAssetLoading, setIsAssetLoading] = useState(false);
    const [nextPage, setNextPage] = useState(0);
    const [needToLoad, setNeedToLoad] = useState(false);
    const lastItem = useRef();
    const observer = useRef();
    const [isAddingAsset, setIsAddingAsset] = useState(false);
    const [copyAsset, setCopyAsset] = useState(false);
    const [showPhoto, setShowPhoto] = useState(false);
    const [editAsset, setEditAsset]  =useState(false)


    // перечень всех устройств
    const [assetItems, setAssetItems] = useState();
    const [totalAssetItems, setTotalAssetItems] = useState();

    // поиск и фильтрация
    const [searchText, setSearchText] = useState("");
    const [platformList, setPlatformList] = useState(null);
    const [platformFilter, setPlatformFilter] = useState(false);
    const [newFilter, setNewFilter] = useState(null)
    const [whFilter, setWhFilter] = useState(null);
    // eslint-disable-next-line no-unused-vars

    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);


   const fetchData=async(add) => {
            let filters = false;

            if (platformFilter || searchText || newFilter || whFilter) {
                filters = {
                    platformFilter: platformFilter,
                    searchText: searchText,
                    newFilter: newFilter,
                    whFilter: whFilter

                };
            }

            setIsPreloader(true);
            setIsAssetLoading(true);

            const id = props.orgId ? props.orgId : false;
            console.log(nextPage)
            await WarehouseService.getList(id, filters, add ? nextPage : 1)
            .then((res) => {
                if (add === 'add') {setNextPage(res.next ? nextPage + 1 : null);  res.results?.map(item =>  setAssetItems(arr => [...arr, item])); }
                else {  setAssetItems(res.results); setNextPage(res.next ? 2 : null); }
                setTotalAssetItems(res.count);
                setTimeout(()=>setIsAssetLoading(false), 1500);
            }).finally(() => setIsPreloader(false))
           // await WarehouseService.getStats().then((res) => setAssetsStats(res)).catch(() => changeStatus());
    }

    useEffect(() => {

        setIsPreloader(true)
        const timerRefresh = setTimeout(()=> fetchData(false),500);
        return () => {

            clearTimeout(timerRefresh)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAddingAsset, platformFilter, searchText, newFilter, copyAsset, successDelete, whFilter, editAsset]);

    //Платформа
    useEffect(() => {
        (async () => {
            await PlatformService.getList().then((res) => setPlatformList(res)).catch(() => changeStatus());

        })();
    }, []);


    useEffect(() => {

        (async () => {
            if (needToLoad &&nextPage) {

                await fetchData('add');
                setNeedToLoad(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needToLoad]);

    useEffect(() => {

        if (isAssetLoading) return;

        if (observer.current) observer.current.disconnect();

        var callback = function (entries, observer) {
            if (entries[0].isIntersecting && nextPage) {
           setNeedToLoad(true)
            }
        };
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastItem.current);

    }, [isAssetLoading]);

    const clearFilters = () => {
        setPlatformFilter(false);
        setSearchText("");
        setNewFilter(null);
        setWhFilter(null);
    }

    const refreshToken = async (id) => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                removeAsset(id);
            })
            .catch(() => changeStatus())
    }

    const removeAsset = async (assetId) => {

        await WarehouseService.deleteAsset(assetId)
        .then(() => { setSuccessDelete(!successDelete); setDeletingAsset(false); setErrors([]) })
        .catch((err) => {
            if (err.message[0].includes('token')) refreshToken(assetId);
            else {
                setErrors(err.message);
            }
        })
    }
    const downloadFile=async ()=> {
        let fileType = 'xlsx';
        setIsPreloader(true)
        await WarehouseService.getExportExlsWarehouse().then((resp)=>{
            const date = (new Date()).toISOString().replace('.', '-').split('T')[0];

            const effectiveFileName = `warehouse-${date}.${fileType}`;
            const url = window.URL.createObjectURL(new Blob([resp]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', effectiveFileName);
            document.body.appendChild(link);
            link.click();
            link.remove();

        }).catch((err) => { setErrors(err.message)}).finally(()=> setIsPreloader(false));

    }
    return (
        <>

            <Col>
                {<h3 className="mb-5">Склад (всего {totalAssetItems} устройств)</h3>}

                <Row> {errors.length > 0 &&
                    errors.map(error =>
                        <Alert key="danger" variant="danger">
                            {error}
                        </Alert>
                    )
                }</Row>
{copyAsset && 
                <MyModal visible={copyAsset} setVisible={setCopyAsset}>
                    <WarehouseCopyForm asset={currentAsset} setIsCopyAsset={setCopyAsset}/>
                </MyModal>
                }
                {deletingAsset &&
                    <MyConfirm visible={deletingAsset} setVisible={setDeletingAsset}>
                        <Row>

                            <h5 className="mb-3">Удалить устройство?</h5>
                        </Row>
                        <Row> {errors.length > 0 &&
                            errors.map(error =>
                                <Alert key="danger" variant="danger">
                                    {error}
                                </Alert>
                            )
                        }</Row>
                        <Row>
                            <Col >
                                <Button variant="outline-primary" size="sm" type="submit " style={{ width: '100%' }} onClick={(e) => { e.preventDefault(); removeAsset(currentAsset.id) }}>
                                    Да
                                </Button>

                            </Col>
                            <Col >
                                <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); setDeletingAsset(false); setErrors([]) }} style={{ width: '100%' }}>
                                    нет
                                </Button>
                            </Col>
                        </Row>

                    </MyConfirm>}
                {showPhoto &&
                    <MyModal visible={showPhoto} setVisible={setShowPhoto}>

                        <WarehousePhoto images={currentAsset?.images} />

                    </MyModal>}

                <Row>
                    <Col className="mb-3 col-2">
                       {!isAddingAsset && <Button variant="outline-success" size="sm" style={{ fontSize: '1rem', width: '100%' }}
                            onClick={() => { setIsAddingAsset(true) }}

                        >Добавить</Button>}

                    </Col>
                    <Col className="mb-3 col-2">
                        { !isAddingAsset && <Button type="link" variant="outline-success" size="sm" style={{ fontSize: '1rem', width: '100%' }}
                            onClick={() => { downloadFile() }}

                        >Скачать в Excel</Button>}

                    </Col>
                </Row>  <hr />

                {/* ФИЛЬТРЫ */}

                <Row className="justify-content-start">
                    {isAddingAsset && <AddWarehouseForm setIsAddingAsset={setIsAddingAsset}
                    />}
                    {editAsset && <EditWarehouseForm setIsAddingAsset={setIsAddingAsset} currentAsset={currentAsset} setCopyAsset={setEditAsset}
                    />}
                    <Col className="mb-3 col-3" >
                        <Form.Label size="sm">Поиск</Form.Label>
                        <Form.Control onChange={(e) => {setSearchText(e.target.value)}}
                            placeholder="Начните вводить..."
                            size="sm"
                            style={{ fontSize: '1rem', height: 38 }}
                            value={searchText}
                           // disabled={isAssetLoading}
                        />
                    </Col>
                    <Col className="mb-3 col-3 ">
                        <Form.Label size="sm">Платформа</Form.Label>
                        <Dropdown style={{ width: "100%" }}>
                            <Dropdown.Toggle as={MyToggleAsset} id="dropdown-platforms">
                                {platformFilter.name || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                setPlatformFilter={setPlatformFilter}
                                type='platform'
                            >
                                {platformList?.map(platform =>
                                    <Dropdown.Item eventKey={"apl" + platform.id} key={"apl" + platform.id} onClick={() => { setPlatformFilter(platform) }}>{platform.name}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col className="mb-3 col-3" >
                        <Form.Label size="sm">Новое или Б/У</Form.Label>
                        <Dropdown >
                            <Dropdown.Toggle as={MyToggleAsset} id="dropdown-newF" >
                                {newFilter || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                filter={setNewFilter}
                                type='newF'
                            >
                                <Dropdown.Item eventKey={"rm" + 1} key={"rm" + 1} onClick={() => { setNewFilter('new') }}>{'New'}</Dropdown.Item>
                                <Dropdown.Item eventKey={"rm" + 2} key={"rm" + 2} onClick={() => { setNewFilter('mint') }}>{'Mint'}</Dropdown.Item>
                                <Dropdown.Item eventKey={"rm" + 3} key={"rm" + 3} onClick={() => { setNewFilter('near-mint') }}>{'Near-mint'}</Dropdown.Item>
                                <Dropdown.Item eventKey={"rm" + 4} key={"rm" + 4} onClick={() => { setNewFilter('used') }}>{'Used'}</Dropdown.Item>
                                <Dropdown.Item eventKey={"rm" + 5} key={"rm" + 5} onClick={() => { setNewFilter('parts') }}>{'Parts'}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col className="mb-3 col-3" >
                        <Form.Label size="sm">На складе</Form.Label>
                        <Dropdown >
                            <Dropdown.Toggle as={MyToggleAsset} id="dropdown-newWH" >
                                {whFilter || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                filter={setWhFilter}
                                type='newWh'
                            >
                                <Dropdown.Item eventKey={"wh" + 1} key={"wh" + 1} onClick={() => { setWhFilter('Да') }}>{'Да'}</Dropdown.Item>
                                <Dropdown.Item eventKey={"wh" + 2} key={"wh" + 2} onClick={() => { setWhFilter('Нет') }}>{'Нет'}</Dropdown.Item>

                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>

                </Row>
                {<Row>

                    {(platformFilter || searchText || newFilter || whFilter) &&
                        <Col className="col-2">

                            <Button variant="outline-info" size="sm" style={{ width: "100%", height: '38px', margin: '10px 0' }} onClick={() => clearFilters()}>Сбросить фильтры</Button>
                        </Col>}
                </Row>}

                <Row>
                    <Col style={{ position: "relative" }}>

                        <WarehouseList showPhoto={showPhoto} setShowPhoto={setShowPhoto} assetItems={assetItems} copyAsset={copyAsset} setCopyAsset={setCopyAsset} editAsset={editAsset} setEditAsset={setEditAsset} close={props.close} removeAsset={setDeletingAsset} currentAsset={currentAsset} setCurrentAsset={setCurrentAsset} />
                        {isPreloader && <Preloader />}
                        <div ref={lastItem} style={{ height: 20 }} />
                    </Col>
                    
                </Row>

            </Col>
        </>
    );
}
export default WarehouseArea;