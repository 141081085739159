import React, { useState, useEffect, useContext } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Auth from "../../API/Auth";
import { AuthContext } from "../../context";
import Preloader from "../UI/Preloader/Preloader";
import SandboxService from "../../API/SandboxService";
import MyToggleAsset from "../UI/MyDropdown/MyToggleAsset";
import MyMenu from "../UI/MyDropdown/MyMenu";
import { Dropdown } from "react-bootstrap";
import MyToggle from "../UI/MyDropdown/MyToggle";
import { changeHandler, fileTypeSandboxCreate } from "./utils";

function AddSandboxForm({ setAddInstance }) {
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [active, setActive] = useState(false);
    const [comment, setComment] = useState("");
    const [ipAsset, setIpAsset] = useState("");
    const [ipAssetInternal, setIpAssetInternal] = useState("");
    const [apiKey, setApiKey] = useState("");
    const [isPreloader, setIsPreloader] = useState(false);
    const [errors, setErrors] = useState([]);
    const [filePem, setFilePem] = useState(null);
    const [fileKey, setFileKey] = useState(null);
    const [sandboxType, setSandboxType] = useState('palo_alto');
    const [typeFile, setTypeFile] = useState([]);

    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const refreshToken = async (type) => {
        await Auth.refreshToken(localStorage.getItem("freshToken"))
            .then((res) => {
                localStorage.setItem("token", res.data.access);
                setErrors([]);
                saveSandbox();
            })
            .catch(() => changeStatus());
    };

    const saveSandbox = async (event) => {
        if (event) event.preventDefault();
        const formData = new FormData();
        formData.append("name", name);
        if (typeFile.length > 0) formData.append('supported_file_types', typeFile.join(','))
        formData.append("description", comment);
        formData.append("password", password);
        formData.append("external_ip", ipAsset);
        formData.append("internal_ip", ipAssetInternal);
        formData.append("api_key", apiKey);
        formData.append("is_active", active);
        formData.append("type", sandboxType);
        if (filePem) formData.append("ssl_pem", filePem);
        if (fileKey) formData.append("ssl_key", fileKey);
        // Create a test FormData object

        // Display the key/value pairs
        // for (var pair of formData.entries()) {
        //     console.log(pair);
        // }
        setIsPreloader(true);

        await SandboxService.addSandbox(formData)
            .then(() => {
                setAddInstance(false);
                setIsPreloader(false);
            })
            .catch((err) => {
                if (err.message[0].includes("token")) refreshToken();
                else {
                    setErrors(err.message);
                    setIsPreloader(false);
                }
            });
    };
    return (
        <Form id="formSandbox" onSubmit={saveSandbox} className="was-validated">
            {errors?.length > 0 &&
                errors?.map((error) => (
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                ))}
            {isPreloader && <Preloader />}

            {/* <Row>

                <Col className="mb-4 col-2 ">
                    <div
                        className="form-check"
                        style={{ alignItems: "center", display: "flex" }}
                    >
                        <input
                            onChange={(evt) => setActive(evt.target.checked)}
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            checked={active}
                            id="isActive"
                            style={{ height: "25px", width: "25px" }}
                        ></input>
                        <label
                            className="form-check-label"
                            htmlFor="isMain"
                            style={{
                                fontSize: "14px",
                                fontWeight: 600,
                                marginLeft: "10px",
                                color: "black",
                            }}
                        >
                            Active
                        </label>
                    </div>
                </Col>
            </Row> */}

            <Row>
                <Col sm={4}>
                    <Form.Group>
                        <Form.Label size="sm">Название</Form.Label>
                        <Form.Control
                            size="sm"
                            type="text"
                            value={name}
                            required={true}
                            placeholder={"Введите название"}
                            onChange={(e) => {
                                setName(e.target.value);
                            }}
                        />
                        <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-4">
                    <Form.Group >
                        <Form.Label size="sm">Тип</Form.Label>
                        <Form.Select size="sm" id="isActive" onChange={(e) => setSandboxType(e.target.value)} >
                            <option value={'palo_alto'}>Palo Alto Networks</option>
                            <option value={'forti'}>Fortinet</option>
                            <option value={'kaspersky'}>Kaspersky</option>
                            <option value={'kaspersky_sandbox'}>Kaspersky Sandbox</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group>
                        <Form.Label size="sm">Описание</Form.Label>
                        <Form.Control
                            size="sm"
                            type="text"
                            value={comment}
                            required={true}
                            onChange={(e) => setComment(e.target.value)}
                            placeholder={"Введите описание"}
                        />
                        <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                </Col>
               
            </Row>

            <Row className="mb-3">
            <Col sm={4}>
                    <Form.Group>
                        <Form.Label size="sm">{sandboxType==='forti'? 'API key': ' Имя пользователя'}</Form.Label>
                        <Form.Control
                            size="sm"
                            type="text"
                            value={apiKey}
                            required={true}
                            placeholder={sandboxType==='forti'? ' Введите API key': ' Введите имя пользователя'}
                            onChange={(e) => {
                                setApiKey(e.target.value);
                            }}
                        />
                        <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group>
                        <Form.Label size="sm">Пароль</Form.Label>
                        <Form.Control
                            size="sm"
                            type="text"
                            value={password}
                            required={true}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder={"Задайте пароль"}
                        />
                        <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                </Col>
                </Row>
                <Row className="mb-3">
                <Col sm={4}>
                    <Form.Group>
                        <Form.Label size="sm">Внешний IP</Form.Label>
                        <Form.Control
                            size="sm"
                            type="text"
                            value={ipAsset}
                            required={true}
                            placeholder={"Введите внешний IP"}
                            onChange={(e) => {
                                setIpAsset(e.target.value);
                            }}
                        />
                        <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group>
                        <Form.Label size="sm">{sandboxType==='forti'? 'Внутренний IP': ' Серийный номер'}</Form.Label>
                        <Form.Control
                            size="sm"
                            type="text"
                            value={ipAssetInternal}
                            required={true}
                            placeholder={sandboxType==='forti'? "Введите внутренний IP": 'Введите серийный номер'}
                            onChange={(e) => {
                                setIpAssetInternal(e.target.value);
                            }}
                        />
                        <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-4">
                    <Form.Label size="sm">Поддерживаемые типы файлов</Form.Label>
                    <Dropdown  style={{ border: "1px solid green", height: 31, borderRadius: 6, paddingLeft: '.5rem' }} autoClose="outside">
                        <Dropdown.Toggle as={MyToggle} id="dropdown-type"   size={typeFile?.length>0 ? 'min': 'normal'}>
                        {(typeFile?.length && typeFile?.map((item, index) => item + (index === typeFile.length -1 ? ' ': ', '))) || "выбрать"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            as={MyMenu}
                            filterAssets={setTypeFile}
                            type="typeFilter"
                        >
                        { fileTypeSandboxCreate?.map((item)=>  <Dropdown.Item
                        className={typeFile.some((it) => { return item.key === it}) === true ? 'active' : ""}
                                eventKey={item.key}
                                key={item.key}
                                onClick={() => {
                                    changeHandler(item.title, typeFile, setTypeFile)
                                 
                                }}
                            >
                                {item.title}
                            </Dropdown.Item>)}
                           
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>
            <Row className="mb-3">
               {sandboxType!=='forti' && sandboxType!=='kaspersky_sandbox' &&  <Col className="mb-3 col-4">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Файл SSL PEM</Form.Label>
                        <Form.Control
                            type="file"
                            size="sm"
                            onChange={(e) => setFilePem(e.target.files[0])}
                            // accept='application/pdf, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .docx'
                        />
                    </Form.Group>
                </Col>}
                {sandboxType==='palo_alto' &&  <Col className="mb-3 col-4">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Файл SSL KEY</Form.Label>
                        <Form.Control
                            type="file"
                            size="sm"
                            onChange={(e) => setFileKey(e.target.files[0])}
                            // accept='application/pdf, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .docx'
                        />
                    </Form.Group>
                </Col>}
                <Col className="col-2">
                    <div
                        className="form-check form-switch"
                        style={{
                            minHeight: 0, display: "flex", flexDirection: "column"
                        }}
                    >
                           <Form.Label size="sm" style={{marginLeft: '-40px'}}>Activate</Form.Label>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="statusChecks"
                            checked={active}
                            style={{ height: "25px", width: "50px"}}
                            onChange={(evt) => setActive(evt.target.checked)}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="mb-3 col-2">
                    <Form.Group className="mb-4" >
                        <Button
                            style={{ width: "100%" }}
                            variant="outline-danger"
                            size="sm"
                            onClick={() => {
                                setAddInstance(false);
                            }}
                        >
                            Отменить
                        </Button>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-2">
                    <Form.Group className="mb-4">
                        <Button
                            style={{ width: "100%" }}
                            variant="outline-primary"
                            size="sm"
                            type="submit"
                        >
                            Сохранить
                        </Button>
                        <br />
                    </Form.Group>
                </Col>
            </Row>

            <hr />
        </Form>
    );
}
export default AddSandboxForm;
