export const fileTypeSandboxCreate= [
    {title: 'pe', key: 'pe'},
    {title: 'pdf', key: 'pdf'},
    {title: 'ms-office', key: 'ms-office'},
    {title: 'jar', key: 'jar'},
    {title: 'flash', key: 'flash'},
    {title: 'archive', key: 'archive'},
    {title: 'script', key: 'script'},
    {title: 'apk', key: 'apk'},
    {title: 'linux', key: 'linux'},
]
export function changeHandler(current, arr, set) {

    if (arr?.some((item) => item === current) !== true) {
        set([...arr, current]);
        //setSlugFilter(true)
    } else { const index = arr?.findIndex((item) => current === item); set([...arr?.slice(0, index), ...arr?.slice(index + 1)]) }
}
export const getVerdictBadgeKasper = (verdict, item) => {
    if (verdict === "Malware")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-danger"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Not applicable")
      return (
        <span
          style={{
            marginRight: 10,
            fontSize: "x-small",
            background: "#ff7a45",
          }}
          className="badge"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Adware and other")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-warning"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Not categorized")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small", background: "grey" }}
          className="badge"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Clean")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-success"
        >
          {verdict}
        </span>
      );
    else if (verdict === null)
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-light text-dark"
        >
          No data
        </span>
      );
    else if (verdict === "Unknown")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small", background: "grey" }}
          className="badge"
        >
          {verdict}
        </span>
      );
      else if (verdict === "NotAVirus")
      return (
        <span
        style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-warning"
        >
          {verdict}
        </span>
      );
  };
  export const getVerdictPaBadge = (verdict, item) => {
    if (verdict === "Benign")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small", background: "black" }}
          className="badge bg-success"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Unsupported")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-warning"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Error")
      return (
        <span
          style={{
            marginRight: 10,
            fontSize: "x-small",
            background: "#9e1068",
          }}
          className="badge"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Unknown")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small", background: "grey" }}
          className="badge "
        >
          {verdict}
        </span>
      );
    else if (verdict === null)
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-light text-dark"
        >
          No data
        </span>
      );
    else
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-danger"
        >
          {verdict}
        </span>
      );
  };
  export const getVerdictBadgeForti = (verdict, item) => {
    if (verdict === "Clean")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-success"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Low Risk")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-warning"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Unknown")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small", background: "grey" }}
          className="badge"
        >
          {verdict}
        </span>
      );
    else if (verdict === null)
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-light text-dark"
        >
          No data
        </span>
      );
    else
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-danger"
        >
          {verdict}
        </span>
      );
  };
  export const getVerdictTVBadge = (verdict, item) => {
    if (verdict === "Benign")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small", background: "black" }}
          className="badge bg-success"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Unknown")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small", background: "grey" }}
          className="badge "
        >
          {verdict}
        </span>
      );
    else if (verdict === null)
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-light text-dark"
        >
          No data
        </span>
      );
    else
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-danger"
        >
          {verdict}
        </span>
      );
  };